import React, { Component } from 'react';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
  }

  componentDidMount() {
    const url = "https://opendata.dwd.de/climate_environment/health/alerts/s31fg.json";
    fetch(url,  { 
      method: 'GET',
      headers:
      {
        method: "GET",
        mode: "cors",
        headers: {"content-type": "application/json" }
      }
    })
    .then(response => response.json())
    .then(json => this.setState({ posts: json.content }))
  }
  
  render() {
    const { posts } = this.state;
    console.log(posts);
    return (
      <p>Hello world!</p>
    );
  }
}
export default App;